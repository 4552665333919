import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Section } from 'src/components/molecules/Section';
import { Image } from 'src/containers/Image2';
import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import { Grid } from 'src/system';

export default function Drawer({ preHeading, contentNode, heading, ctas, customData, image }) {
  customData = JSON.parse(customData);
  const cta = ctas[0];
  const data = useStaticQuery(graphql`
    query {
      saraSaraEducationSvg: file(relativePath: { eq: "sara/sara-education.svg" }) {
        ...Image2NoSharp
      }
    }
  `);
  return (
    <Section {...customData?.flags}>
      <Grid className="cols-auto" style={{ '--column-min-width': '400px', justifyItems: 'center' }}>
        <div>
          <div>
            <Image data={data.saraSaraEducationSvg} role="presentation" width="45" inline /> {preHeading}
          </div>
          <h2>{heading}</h2>
          <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
          <p>
            <strong>
              <Link to={cta.url}>{cta.text}</Link>
            </strong>
          </p>
        </div>
        <div>
          <Link to={cta.url}>
            <FluidImg data={image} maxWidth={640} />
          </Link>
        </div>
      </Grid>
    </Section>
  );
}
